import logo from './logo.svg';
import './App.css';
import { AccessibilityIcon,MagicWandIcon,EnvelopeOpenIcon,EnvelopeClosedIcon,BackpackIcon,FileTextIcon,CheckCircledIcon,IdCardIcon,ArchiveIcon,ReloadIcon } from '@radix-ui/react-icons'
import React, { useCallback, useRef } from 'react';
import ReactFlow, { useNodesState,Background, useEdgesState, addEdge,Controls, ControlButton } from 'reactflow';
import { Tooltip } from 'flowbite-react';
import 'reactflow/dist/style.css';


const nodeStyle = {
  color: '#0041d0',
  borderColor: '#0041d0',
};

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, type: 'input',data: { label: 'Start' },style: nodeStyle },
];
const initialEdges = [
  // { id: 'e1-2', source: '1', target: '2' }
];

function App() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const yPos = useRef(0);
  
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );
 
  const Emails = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Send Email' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Job = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Job Select' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const FileUp = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'File Upload' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Payments = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Payment' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Checks = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Check' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Appointments = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Appointment' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const SignD = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Sign Document' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Call = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Call' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Mailssent = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Mail Sent' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  const Refund = useCallback(() => {
    yPos.current += 50;
    setNodes((els) => {
      console.log(els);
      return [...els,{
        id:Math.random().toString(),
        position: {x:0,y:yPos.current},
        data: { label: 'Re fund' },
        height: 38,
        width: 150,
        style: nodeStyle,
      }]
    });
    
  },[])
  return (
    <div style={{ width: '100vw', height: '100vh',backgroundColor:"#202020" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        <Controls>
          
          <ControlButton onClick={Job} >
            <BackpackIcon />
          </ControlButton>
          <ControlButton onClick={FileUp}>
            <FileTextIcon />
          </ControlButton>
          <ControlButton onClick={Payments}>
            <IdCardIcon />
          </ControlButton>
          <ControlButton onClick={Emails}>
            <EnvelopeOpenIcon />
          </ControlButton>
          <ControlButton onClick={Checks}>
            <CheckCircledIcon />
          </ControlButton>
          <ControlButton onClick={Appointments}>
            <AccessibilityIcon />
          </ControlButton>
          <ControlButton onClick={SignD}>
            <ArchiveIcon />
          </ControlButton>
          <ControlButton onClick={Call}>
            <MagicWandIcon />
          </ControlButton>
          <ControlButton onClick={Mailssent}>
            <EnvelopeClosedIcon />
          </ControlButton>
          <ControlButton onClick={Refund}>
            <ReloadIcon />
          </ControlButton>
        </Controls>
        <Background variant="dots" gap={30} size={1} />
        </ReactFlow>
    </div>
  );
}

export default App;
